/* ==================================================================
FANCY BOX
================================================================== */
#fancybox-overlay{
	background-color: rgba(0, 0, 0, 0.8) !important;
	opacity:1 !important;
}
#fancybox-left-ico{
	left:-38px !important;
}
#fancybox-right-ico{
	right:-38px !important;
}
#fancybox-left-ico{
	background:url('images/prev.svg') center center no-repeat;
	background-size:contain;
}
#fancybox-right-ico{
	background:url('images/next.svg') center center no-repeat;
	background-size:contain;
}
#fancybox-left-ico, #fancybox-right-ico{
	height: 44px;
    margin-top: -22px;
}
#fancybox-close{
	background:url('images/close.svg') center center no-repeat;
	top: -40px;
    right: -2px;
    width: 30px;
    height: 30px;
}
#fancybox-outer{
	background:none;
}
.fancybox-title-inside{
	background:#000;
	color:#FFF;
	margin-top:10px;
	text-align: left;
	padding:0;
}
#fancybox-title-inside{
	padding:16px;
}
.fancybox-bg{
    background: none !important;   
}