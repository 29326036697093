@mixin couleur_materialize($couleur){
	.btn, .btn-large, .btn-small{
		background:$couleur;
		&:hover, &:focus{
			background:darken($couleur, 10%);
		}
	}
	[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before, [type="radio"].with-gap:checked+span:after{
		border: 2px solid $couleur;
	}
	[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:after{
		background:$couleur;
	}
	[type=checkbox].filled-in:checked+span:not(.lever):after{
		border-color: $couleur;
		background:$couleur;
	}
	.input-field input:focus, .input-field input.valid, .input-field input.invalid{
		box-shadow:0 1px 0 $couleur !important;
	}
	.input-field label.active{
		color:$couleur !important;
	}
	.dropdown-content li>a, .dropdown-content li>span{
		color:$couleur;
	}
	input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]){
		border-bottom: 1px solid $couleur;
		-webkit-box-shadow: 0 1px 0 0 $couleur;
		box-shadow: 0 1px 0 0 $couleur;
	}
}