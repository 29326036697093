$bleu: #3E9CE0;
$jaune: #F0BF30;
$turquoise: #64D3DB;
$rouge: #EA2222;
$gris: #F7F8FC;
$font1: 'Open Sans', sans-serif;
$font2: 'Poppins', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap'); 

@import "../../mixins/style_fancybox.scss";
@import "../../mixins/couleur_materialize.scss";

@include couleur_materialize($bleu);

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	&::before {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
}
html {
	margin: 0;
	padding: 0;
	color: #000000;
	background: #FFF;
	font-family: $font1;
	font-weight:500;
}
body {
	margin: 0;
	padding: 0;
	color: #000000;
	background: #FFF;
	font-family: $font1;
	font-weight:500;
	font-size: 13px;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	&.active {
		overflow: hidden;
	}
}
a {
	text-decoration: none;
	-webkit-transition: all .2s ease-in;
	-moz-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	transition: all .2s ease-in;
	font-weight: 300;
	color: inherit;
}
p {
	-webkit-transition: all .2s ease-in;
	-moz-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	transition: all .2s ease-in;
	font-weight: 300;
	margin: 10px 0;
	font-size: 1em;
	line-height:1.4em;
}
li {
	-webkit-transition: all .2s ease-in;
	-moz-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	transition: all .2s ease-in;
	font-weight: 300;
}
input {
	-webkit-transition: all .2s ease-in;
	-moz-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	transition: all .2s ease-in;
	font-weight: 300;
}
.bouton {
	-webkit-transition: all .2s ease-in;
	-moz-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	transition: all .2s ease-in;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	-webkit-transition: 0.2s ease;
	-moz-transition: 0.2s ease;
	transition: 0.2s ease;
	color: #FFF;
	z-index: 1;
	position: relative;
	overflow: hidden;
	padding: 12px 40px;
	margin: 2px;
	background:$bleu;
	border-radius:150px;
	z-index: 0;
	line-height:normal;
	font-family:$font2;
	font-weight:700;
	&:focus{
		background:$bleu;
	}
	&:hover{
		background:darken($bleu, 5);
	}
	&.jaune{
		background:$jaune;
		color:#FFF;
		&:hover{
			background:darken($jaune, 5);
		}
	}
	&.bouton_blanc{
		border:1px solid $bleu;
		color:$bleu;
		display:block;
		background:#FFF;
		text-align: center;;
		&:hover{
			color:#FFF;
			background:$bleu;
		}
	}
}
.card{
	padding:40px;
	margin:10px auto;
	border-radius: 10px;
	box-shadow:0 0 50px rgba(0, 0, 0, 0.08);
	background:#FFF;
	@media only screen and (max-width:800px){
		padding:20px;
	}
}
button {
	cursor: pointer;
	border: none;
}

img {
	max-width: 100%;
}
b {
	font-weight: 600;
}
strong {
	font-weight: bold;
}
.bleu{
	color:$bleu;
	.ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut{
		stroke:$bleu;
	}
	.ct-series-b .ct-bar, .ct-series-b .ct-line, .ct-series-b .ct-point, .ct-series-b .ct-slice-donut{
		stroke:rgba($bleu,0.3);
	}
}
.jaune{
	color:$jaune;
	.ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut{
		stroke:$jaune;
	}
	.ct-series-b .ct-bar, .ct-series-b .ct-line, .ct-series-b .ct-point, .ct-series-b .ct-slice-donut{
		stroke:rgba($jaune,0.3);
	}
}
.turquoise{
	color:$turquoise;
	.ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut{
		stroke:$turquoise;
	}
	.ct-series-b .ct-bar, .ct-series-b .ct-line, .ct-series-b .ct-point, .ct-series-b .ct-slice-donut{
		stroke:rgba($turquoise,0.3);
	}
}
.rouge{
	color:$rouge;
	.ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut{
		stroke:$rouge;
	}
	.ct-series-b .ct-bar, .ct-series-b .ct-line, .ct-series-b .ct-point, .ct-series-b .ct-slice-donut{
		stroke:rgba($rouge,0.3);
	}
}
h1 {
	font-size: 2em;
	margin:auto;
	color:$bleu;
	font-family:$font2;
	font-weight:700;
	@media only screen and (max-width:800px){
		font-size:1.4em;
	}
}
h2 {
	@extend h1;
	margin-bottom:10px;
}
h3 {
	margin:auto auto 10px;
	text-transform: uppercase;
	font-size: 1.1em;
	color:#818181;
	font-weight:600;
}
h4 {
	margin:auto auto 20px;
	font-size: 1.5em;
	color: #000;
	font-weight:600;
	font-family:$font2;
}
h5 {
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
	font-size: 1em;
	line-height: inherit;
	margin: 0;
	text-transform: uppercase;
	letter-spacing: 0.1em;
}
h6 {
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
}
.text_ck {
	strong{
		color:$bleu;
	}
	li {
		margin: 10px 0;
		font-size: 1em;
	}
	ul {
		list-style-type: none;
		padding: 0;
		li {
			&:before {
				content: '';
				margin-right: 10px;
				background: $bleu;
				height: 5px;
				width: 5px;
				display: inline-block;
				vertical-align: middle;
			}
			list-style-type: none;
			margin: 2px auto;
		}
	}
}
.texte {
	li {
		margin: 10px 0;
		font-size: 1em;
	}
}
.sous-titre {
	color: #FFF;
	p {
		color: #FFF;
	}
}
.titre_page {
	color: #000;
}
ul {
	text-align: left;
	list-style-type: initial;
	padding: initial;
	padding-left: 20px;
	li {
		list-style-type: initial;
	}
}
#header-page {
	padding:40px;
	text-align:center;
	height:140px;
	background:$gris;
	position:relative;
	h1{
		background:$turquoise;
		display: inline-block;
		vertical-align: middle;
		padding:10px 20px;
		color:#FFF;
		position:absolute;
		bottom:-24px;
		left:50%;
		transform: translateX(-50%);
	}
	&.titre_absolute{
		height:300px;
		background-size:cover;
		background-position:center center;
		background-repeat: no-repeat;
		@media only screen and (max-width:1000px){
			height:150px;
		}
	}
}
td {
	padding: 0px;
}
th {
	padding: 0px;
}
.parallax {
	z-index: 1 !important;
	height: 400px;
    overflow: hidden;
	img{
		max-width:none !important;
		min-width:101% !important;
		opacity:1 !important;
	}
}
.parallax-container{
	height: 400px;
	max-width: none !important;
	background:$gris;
	.cols_1 {
		height: 100%;
	}
}
.article{
	.parallax-container{
		background:#FFF;
	}
}

#responsive-content {
	>div.no_max_width {
		max-width: none;
	}
	margin: auto;
	position: relative;
	&.cookies, &.mentionsLegales{
		max-width: 1200px;
		margin: 60px auto;
	}
}
.bloc_full_width {
	.cell_50 {
		.texte {
			padding: 60px 10%;
		}
	}
}
.image_cover{
	height:300px;
}
.module_slider{
	height:650px;
	margin-top:-110px;
	.lslide{
		height:650px;
	}
}
#slider {
	display: block;
	position: relative;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 500px;
	.content-slider {
		>li {
			height: 500px;
			display:flex;
			.content-slide{
				margin:auto;
				height:auto;
			}
		}
	}
}
#accueil{
	#container_slider_home{
		border-bottom: $bleu solid 130px;
		#slider {
			height: 300px;
			.content-slider {
				>li {
					height: 300px;
					min-height:0;
					background-position: bottom center;
				}
			}
			@media only screen and (max-width:1500px){
				height: 200px;
				.content-slider {
					>li {
						height: 200px;
					}
				}
			}
			@media only screen and (max-width:1200px){
				height: 150px;
				.content-slider {
					>li {
						height: 150px;
					}
				}
			}
		}
		@media only screen and (max-width:1200px){
			border-bottom: $bleu solid 100px;
		}
	}
}

.content-slide{
	display:flex;
	flex-direction: column;
	height:100%;
	.div_texte_central1{
		margin:auto;
		display:inline-block;
		vertical-align: middle;
		padding:10px 16px;
		background:rgba($bleu, 0.8);
		line-height:normal;
	}
	.div_texte_central2{
		margin:10px auto auto;
		display:inline-block;
		vertical-align: middle;
		padding:4px 12px;
		color:$bleu;
		background:rgba(#FFF, 0.8);
		font-size:1.2em;
	}
}
.wrapper {
	display: block;
	width: 100%;
	flex: 1 0 auto;
	margin-top:89px;
	@media only screen and (max-width:1200px){
		margin-top:75px;
	}
	.lSSlideWrapper {
		.lSSlide {
			-webkit-transform: translate(0px, 0px);
			-ms-transform: translate(0px, 0px);
			transform: translate(0px, 0px);
			-webkit-transition: all 1s;
			-webkit-transition-property: -webkit-transform, height;
			-moz-transition-property: -moz-transform, height;
			transition-property: transform, height;
			-webkit-transition-duration: 1s !important;
			transition-duration: 1s !important;
		}
	}
	.lSSlideOuter.vertical {
		.lSAction {
			right: 30px;
			bottom: 90px;
			width: 120px;
			position: absolute;
			height: 70px;
			left: auto;
			>.lSNext {
				background-position: 0 -31px;
				right: 0;
				bottom: 0;
				left: auto;
				top: auto;
			}
			>.lSPrev {
				background-position: 0 -31px;
				left: 0;
				bottom: 0;
				right: auto;
				top: auto;
			}
		}
	}
	.lSSlideOuter {
		.lSPager.lSpg {
			display:none;
			margin:auto !important;
			position:absolute;
			right:30px;
			top:50%;
			transform:translateY(-50%) !important;
			z-index: 10;
			li{
				display:block;
				margin:14px auto;
				padding:0;
				&:hover{
					a{
						background:#FFF;
					}
				}
				a{
					display:block;
					height:14px;
					width:14px;
					background:none;
					border:2px solid #FFF;
				}
				&.active{
					a{
						background:#FFF;
					}
				}
			}
		}
	}
	.lSAction {
		>a {
			position: absolute;
			background: none;
			margin-top: 0;
			background: rgba(0, 0, 0, 0.8);
			top: 0;
			opacity: 0.8;
			&:hover {
				opacity: 1;
			}
		}
		>a.lSPrev {
			left: 0;
			&:before {
				content: "";
				background: url(../../../images/sites/soyez_visible/icone-prev2.png) center center no-repeat;
				background-size: 20px auto;
				display: block;
				width: 70px;
				height: 70px;
			}
		}
		>a.lSNext {
			right: 0;
			&:before {
				content: "";
				background-size: 20px auto;
				display: block;
				width: 70px;
				height: 70px;
				background: url(../../../images/sites/soyez_visible/icone-next2.png) center center no-repeat;
				background-size: 20px auto;
			}
		}
	}
	.slide.active {
		z-index: 10 !important;
	}
}
.separateur_horizontal {
	clear: both;
	margin: 20px 0;
	height: 1px;
	border: none;
	width: 100%;
	background: rgba(0, 94, 129, 0.2);
}
.content-slider {
	padding: 0;
	margin: 0;
	li {
		text-align: center;
		padding: 0;
		position: relative;
		display: table;
		width: 100%;
		overflow: hidden;
		color: #FFF;
		background-size: cover;
		background-position: center center;
	}
}
.div-obligatoire {
	color: #000;
}
.separate {
	background: #c5c5c5;
	height: 1px;
	margin: 20px 0;
}
.row {
	max-width: 1200px;
}
.max-width {
	max-width: 1200px;
	margin: auto;
}
.vParent {
	min-height: 350px;
	display: table;
	position: relative;
	margin: 0 auto;
	width: 100%;
}
.vCenter {
	max-width: 1200px;
	display: table-cell;
	vertical-align: middle;
}
.padding {
	padding: 60px 0px;
}
.table {
	.cols {
		display: table-cell;
		vertical-align: middle;
		float: none;
	}
}
.cols_2 {
	&:first-child {
		padding-right: 20px;
	}
	&:last-child {
		padding-left: 20px;
	}
}
.center {
	text-align: center;
	width: 100%;
	>img {
		margin: auto;
	}
	>.btn {
		margin: 20px auto;
	}
}
.container-texte {
	padding: 20px;
}
#breadcrumb {
	>ul {
		display: flex;
		align-items: center;
		max-width: 1200px;
		margin: 25px auto;
		padding: 0px;
	}
	>li {
		list-style-type: none;
		margin: 0 10px;
		font-size: 0.9em;
	}
	li {
		&:last-child {
			span {
				font-weight: bold;
			}
		}
	}
}

nav {
	box-shadow: none !important;
}
#header {
	-o-transition: all .2s linear;
	-ms-transition: all .2s linear;
	-moz-transition: all .2s linear;
	-webkit-transition: all .2s linear;
	transition: all .2s linear;
	width: 100%;
	z-index: 100;
	text-align: center;
	background:#FFF;
	display:flex;
	align-items: center;
	justify-content: center;
	padding:0 40px;
	padding-left: 190px;
	position:fixed;
	top:0;
	width:100%;
	left:0;
	border-bottom:1px solid $gris;
	#logo {
		width: 135px;
		position:absolute;
		top:50%;
		left:40px;
		transform:translateY(-50%);
		img{
			display:block;
		}
	}
	.menu {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: right;
		>ul {
			width: 100%;
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin: 0;
			text-align: right;
			padding: 0;
			float: none;
			-o-transition: all .2s linear;
			-ms-transition: all .2s linear;
			-moz-transition: all .2s linear;
			-webkit-transition: all .2s linear;
			transition: all .2s linear;
			margin: auto;
			height: inherit;
		}
		.soussous_menu {
			position: absolute;
			transform: none;
			left: -100%;
			top: 0;
			width: 100%;
			display: none !important;
			transition: 0.2s ease;
			background: #FFF;
			box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
		}
	}
	.menu-item {
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
		vertical-align: middle;
		opacity: 1;
		max-height: none;
		float: none;
		p {
			margin: 0;
		}
		>a, >p {
			display: block;
			vertical-align: middle;
			text-decoration: none;
			cursor: pointer;
			position: relative;
			-o-transition: all .2s ease;
			-ms-transition: all .2s ease;
			-moz-transition: all .2s ease;
			-webkit-transition: all .2s ease;
			transition: all .2s ease;
			padding:35px 15px;
			background: none;
			color: #000000;
			cursor: pointer;
			display: block;
			font-family:$font2;
			font-weight:600;
		}
		>p{
			cursor:inherit;
		}
		&:hover {
			>a {
				color: $jaune;
			}
			>ul {
				opacity: 1;
				visibility: visible;
			}
		}
		&.active{
			> a, > p{
				color: $jaune;
			}
		}
		ul {
			-webkit-transition: all .4s ease;
			-moz-transition: all .4s ease;
			-ms-transition: all .4s ease;
			-o-transition: all .4s ease;
			transition: all .4s ease;
			opacity: 0;
			visibility: hidden;
			background: #ffffff;
			position: absolute;
			top: 70px;
			list-style: none;
			margin: 0;
			padding: 0px;
			z-index: 999;
			min-width: 230px;
			left: 50%;
			transform:translateX(-50%);
			border-radius: 12px;
			padding:10px 0;
			align-items: center;
			-webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
			-moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
			box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
			li {
				padding: 0;
				list-style: none;
				position: relative;
				a {
					display: block;
					vertical-align: middle;
					text-decoration: none;
					cursor: pointer;
					position: relative;
					-o-transition: all .2s ease;
					-ms-transition: all .2s ease;
					-moz-transition: all .2s ease;
					-webkit-transition: all .2s ease;
					transition: all .2s ease;
					font-size: 0.9em;
					padding: 10px 30px;
					background: none;
					cursor: pointer;
					display: block;
				}
				&:hover {
					>a {
						color: $jaune;
					}
					.soussous_menu {
						display: block;
						visibility: visible;
						opacity: 1;
					}
				}
				>a{
					&:before{
						content:url('../../../images/sites/soyez_visible/fleche_bleue.svg');
						position: absolute;
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						width: 16px;
						opacity:0;
						visibility: hidden;
						transition:0.2s ease;
					}
				}
				>a.active, >a:hover{
					color: $jaune;
					padding-left:55px;
					&:before{
						opacity:1;
						visibility: visible;
						left:30px;
					}
				}
			}
		}
	}
}

#menu_bouton {
	height: 55.5px;
	width: 55.5px;
	z-index: 999;
	position: absolute;
	cursor: pointer;
	display: none;
	right: 5px;
	text-align: center;
	top: 11px;
	background-color: transparent;
	border-radius: 50%;
	&:before {
		transform: translateY(-10px);
		-webkit-transform: translateY(-10px);
		-moz-transform: translateY(-10px);
		-ms-transform: translateY(-10px);
		-o-transform: translateY(-10px);
		content: "";
		height: 3px;
		left: 25%;
		margin-top: -3px;
		pointer-events: none;
		position: absolute;
		top: 50%;
		transition: all 0.3s ease 0s;
		-webkit-transition: all 0.3s ease 0s;
		-moz-transition: all 0.3s ease 0s;
		-ms-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		width: 50%;
		background: $bleu;
	}
	&:after {
		transform: translateY(10px);
		-webkit-transform: translateY(10px);
		-moz-transform: translateY(10px);
		-ms-transform: translateY(10px);
		-o-transform: translateY(10px);
		content: "";
		height: 3px;
		left: 25%;
		margin-top: -3px;
		pointer-events: none;
		position: absolute;
		top: 50%;
		transition: all 0.3s ease 0s;
		-webkit-transition: all 0.3s ease 0s;
		-moz-transition: all 0.3s ease 0s;
		-ms-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		width: 50%;
		background: $bleu;
	}
	span {
		display: block;
		content: "";
		height: 3px;
		left: 25%;
		margin-top: -3px;
		pointer-events: none;
		position: absolute;
		top: 50%;
		transition: all 0.3s ease 0s;
		-webkit-transition: all 0.3s ease 0s;
		-moz-transition: all 0.3s ease 0s;
		-ms-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		width: 50%;
		background: $bleu;
	}
}
#menu_bouton.active {
	span {
		opacity: 0;
		background: #fff;
	}
	&:after {
		background: #fff;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
	}
	&:before {
		background: #fff;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		-o-transform: rotate(45deg);
	}
}
.row.max-width {
	max-width: 1200px;
	margin: 30px auto;
	width: 100%;
}

.cards{
	background:#FFF;
	border-radius:10px;
	overflow:hidden;
	padding:30px 45px;
	box-shadow: 0 0 25px rgba(0,0,0,.15);
	position:relative;
	&:after{
		background:$jaune;
		height:10px;
		width:0;
		bottom:0;
		left:0;
		content:'';
		position:absolute;
		transition:0.4s ease 0.4s;
		border-radius:0 0 10px 10px;
	}
	&.active{
		&:after{
			width:100%;
		}
	}
	h3{
		font-size:1.1em;
		text-transform: uppercase;
		margin:10px auto;
	}
	p{
		margin:auto;
	}
}
.group_cols {
	padding: 60px 0;
	margin:auto;
	max-width: 1200px;
	@media only screen and (max-width:1200px){
		padding: 20px 0;
	}
}

#bulle_infos{
	position:fixed;
	bottom:20px;
	left:20px;
	z-index: 11;
	img{
		display:block;
		width:36px;
		box-shadow: 0 0 50px rgba(0,0,0,.08);
		background: #FFF;
		border-radius: 150px;
	}
	span{
		position:absolute;
		top:50%;
		transform:translateY(-50%);
		left:30px;
		white-space: nowrap;
		background:$bleu;
		color:#FFF;
		padding:8px 14px;
		border-radius:4px;
		opacity:0;
		visibility: hidden;
		transition:0.4s ease;
	}
	&:hover{
		span{
			opacity:1;
			visibility: visible;
			left:46px;
		}
	}
}
#yii-debug-toolbar{
	display:none !important;
}
/* ===============================================================================================
ACCUEIL
=================================================================================================*/
#audit_home{
	margin:auto;
	max-width:800px;
	margin-top:-130px;
	z-index: 10;
	position: relative;
	@media only screen and (max-width:1200px){
		max-width:98%;
		transform:none;
		margin-top:-100px;
	}
	h1{
		margin:4px auto;
		text-transform: uppercase;
	}
	p{
		color:$jaune;
		font-size:1.2em;
		font-weight:bold;
	}
	form{
		display:flex;
		justify-content: space-between;
		align-items: flex-end;
		@media only screen and (max-width:800px){
			flex-direction:column;
		}
		.input-field{
			margin:auto 0;
			width:100%;
			input{
				margin:0;
			}
		}
		button{
			white-space: nowrap;
			margin-left:40px;
			min-width: 210px;
			@media only screen and (max-width:800px){
				margin: 10px auto 0 0;
			}
		}
	}
	#erreur_url{
		font-size: 0.9em;
		color: #e84747;
		margin-bottom: 0;
	}
	/*#pastille_home{
		position: absolute;
		height: 125px;
		top: 10px;
		right: 10px;
		@media only screen and (max-width:720px){
			position: relative;
			height: 60px;
			top: 0;
			right: 0;
		}
	}*/
}
.slider_services{
	max-width:none;
	background:$gris;
	position:relative;
	.prev_next_slider{
		position:absolute;
		top:50%;
		transform:translateY(-50%);
		cursor:pointer;
		@media only screen and (max-width:1200px){
			width:30px;
		}
		&.prev_slider{
			left:22px;
			@media only screen and (max-width:1200px){
				left:10px;
			}
		}
		&.next_slider{
			right:22px;
			@media only screen and (max-width:1200px){
				right:10px;
			}
		}
	}
	.lSSlideOuter{
		max-width:calc(100% - 200px);
		margin:auto;
		@media only screen and (max-width:1200px){
			max-width:calc(100% - 100px);
		}
		.lightSlider{
			display:flex !important;
		}
	}
	.container_texte_image{
		@extend .card;
		box-shadow:none;
		position:relative;
		overflow:hidden;
		padding-top: 150px;
		margin:0;
		&.active2{
			&:before{
				top:40px;
			}
			.conteneur_image{
				top:66px;
				opacity:1;
			}
		}
		&:before{
			content:'';
			position:absolute;
			height:90px;
			width:90px;
			top:-90px;
			left:50%;
			transform:translateX(-50%);
			border-radius:150px;
			transition:0.6s ease;
		}
		p{
			margin-bottom:0;
		}
		h3{
			font-size:1.1em;
		}
		.conteneur_image{
			position:absolute;
			top:10px;
			left:50%;
			transform:translateX(-50%);
			transition:0.4s ease 0.4s;
			opacity: 0;
		}
		&:nth-child(3n+1){
			&:before{
				background:$bleu;
			}
			h3{
				color:$bleu;
			}
		}
		&:nth-child(3n+2){
			&:before{
				background:$turquoise;
			}
			h3{
				color:$turquoise;
			}
		}
		&:nth-child(3n){
			&:before{
				background:$jaune;
			}
			h3{
				color:$jaune;
			}
		}
	}
}
.compteur_home{
	background:$gris;
	padding:100px 80px;
	max-width:none;
	text-align:center;
	@media only screen and (max-width:1000px){
		padding:40px;
	}
	.cols_3{
		&:nth-child(2){
			border-left:1px solid $bleu;
			border-right:1px solid $bleu;
		}
	}
	.chiffre{
		color:$jaune;
		font-weight:bold;
		line-height: normal;
		position:relative;
		display: inline-block;
		vertical-align: middle;
		margin:auto;
		span{
			font-size:90px;
			display: inline-block;
			vertical-align: middle;
		}
		sup{
			font-size: 28px;
			top: 46px;
			position: absolute;
			right: -28px;
		}
	}
	p{
		color:#818181;
		margin:auto;
		strong{
			color:#000000;
			text-transform:uppercase;
			font-weight:bold;
			font-size:1.6em;
		}
	}
}

/* ===============================================================================================
AUDIT EN LIGNE
=================================================================================================*/
#audit-en-ligne{
	&.background{
		background:url('../../../images/sites/soyez_visible/fond_audit.svg') center bottom 250px no-repeat;
		background-size:1920px;
	}
}
.preloader-wrapper{
	margin:30px auto;
	.spinner-layer{
		border-color:$jaune;
	}
	.circle-clipper{
		.circle{
			border-width:10px;
		}
	}
}
.card_audit{
	max-width:1200px;
	margin:auto auto 120px;
	padding:0;
	margin-top:-200px;
	z-index: 10;
	@media only screen and (max-width:1200px){
		max-width:98%;
	}
	.header_card{
		padding:10px;
		text-align: center;
		background:$turquoise;
		color:#FFF;
		border-radius:10px 10px 0 0;
		p{
			margin:auto;
			font-weight:700;
			font-family:$font2;
			font-size:2.1em;
			@media only screen and (max-width:800px){
				font-size:1.4em;
			}
		}
	}
	.content_card{
		padding:40px;
		@media only screen and (max-width:1200px){
			padding:20px;
		}
		form{
			margin:40px auto 0
		}
		&#card_audit_formulaire, &#card_audit_analyse, &#card_audit_resultats{
			display:none;
		}
		.progress_bar{
			padding:10px;
			background:$gris;
			border-radius:4px;
			text-align:center;
			margin:30px auto;
			p{
				margin:auto;
				color:#B5B9CC;
				font-size:1.3em;
				.compteur_reponse{
					color:$bleu;
				}
			}
		}
		.question_audit{
			&:not(.question_audit_1){
				display:none;
			}
		}
		.bouton{
			padding: 12px;
			min-width:300px;
			margin-top:40px;
		}
	}
}
[type=checkbox]+span:not(.lever) {
    padding-left: 30px;
    color: #818181;
}
[type=checkbox].filled-in:checked+span:not(.lever):after, [type=checkbox].filled-in:not(:checked)+span:not(.lever):after {
    height: 16px;
    width: 16px;
    border: 2px solid rgba(0, 0, 0, 0.5);
    top: 5px;
}
[type=checkbox].filled-in:checked+span:not(.lever):before {
    top: 3px;
    left: -1px;
}
[type=checkbox].filled-in:checked+span:not(.lever):after {
    border: 2px solid $turquoise;
    background: $turquoise;
}

#card_audit_resultats{
	.item_resultats{
		margin:40px auto;
		width:100%;
		border:none;
		.item_graphique{
			.icone_item_graphique{
				width:auto;
			}
		}
	}
}
#infos_resultats{
	margin:120px auto;
	max-width:1200px;
	display:none;
	padding:0 10px;
	#item_resultats{
		margin-bottom:120px;
		display:flex;
		flex-wrap:wrap;
		justify-content: center;
	}
}
#form_audit{
	margin:140px auto;
	display:none;
}
.item_resultats{
	width:25%;
	text-align:center;
	margin: 40px 0;
	border-right:1px solid #DEDEDE;
	&:nth-child(4), &:nth-child(8), &:last-child{
		border:none;
	}
	@media only screen and (max-width:1000px){
		width:calc(100%/3);
		border-right:1px solid #DEDEDE;
		&:nth-child(4), &:nth-child(8){
			border-right:1px solid #DEDEDE;
		}
		&:nth-child(3), &:nth-child(6), &:nth-child(9), &:last-child{
			border:none;
		}
		@media only screen and (max-width:800px){
			width:calc(100%/2);
			border:none;
			&:nth-child(odd){
				border-right:1px solid #DEDEDE;
			}
			&:nth-child(4), &:nth-child(8){
				border:none;
			}
			@media only screen and (max-width:600px){
				width:calc(100%);
				border:none !important;
			}
		}
	}
	.item_graphique{
		position:relative;
		.icone_item_graphique{
			position:absolute;
			top:calc(50% - 2px);
			left:50%;
			transform:translate(-50%, -50%);
			width:38px;
			margin: auto;
			font-size: 2.6em;
			font-weight:bold;
			font-family:$font2;
		}
		&.jaune{
			.icone_item_graphique{
				path{
					fill:$jaune;
				}
			}
		}
		&.rouge{
			.icone_item_graphique{
				path{
					fill:$rouge;
				}
			}
		}
		&.turquoise{
			.icone_item_graphique{
				path{
					fill:$turquoise;
				}
			}
		}
	}
	h3{
		margin:15px auto 0;
	}
	p{
		margin-top:4px;
	}
}
.collapsible{
	box-shadow:none;
	border:none;
	margin-top:40px;
	.collapsible-header{
		img{
			width:24px;
			margin-right:20px;
		}
		p{
			width:100%;
			color:$bleu;
			font-weight:bold;
		}
		.material-icons{
			margin:auto;
			cursor:pointer;
			transition:0.2s ease;
		}
		&.active{
			.material-icons{
				transform:rotate(180deg);
			}
		}
	}
	.collapsible-body{
		padding:10px 2rem;
		a{
			color:$bleu;
			font-weight:600;
		}
	}
}

table{
	text-align: center;
	@extend .card;
	overflow:hidden;
	margin-top:60px;
	thead{
		background:$turquoise;
		tr{
			border:none;
			th{
				color:#FFF;
				text-transform: uppercase;
				padding:25px 40px;
				border-radius:0;
			}
		}
	}
	tbody{
		tr{
			border:none;
			&:nth-child(even){
				background:$gris;
			}
			td{
				padding:15px 40px;
			}
		}
	}
}
/* ===============================================================================================
ARTICLE
=================================================================================================*/
#header_page_article{
	max-width:1200px;
	margin:20px auto 0;
	padding:0 10px;
	#bouton_retour{
		img{
			display: inline-block;
			vertical-align: middle;
			width: 20px;
			transition: 0.3s ease;
			margin-right: 6px;
		}
		span{
			display: inline-block;
    		vertical-align: middle;
			color:$bleu;
			font-size:0.9em;
		}
		&:hover{
			img{
				transform: translateX(-5px);
			}
		}
	}
	h1{
		text-align: left;
		max-width:1200px;
		margin:15px auto;
		text-transform: none;
		letter-spacing: 1px;
	}
}

/* ===============================================================================================
ARTICLES
=================================================================================================*/
.container_articles{
	.container_item_article{
		display:flex;
		max-width: 90%;
		margin: 100px auto;
		flex-wrap:wrap;
		.item_article{
			@extend .cards;
			box-shadow:none;
			width:calc(100% / 4 - 40px);
			margin:20px;
			padding:0;
			border:none;
			background:$gris;
			&:hover{
				.bouton{
					padding-right: 45px;
				}
			}
			@media only screen and (max-width: 1200px) {
				width:calc(50% - 40px);
				@media only screen and (max-width: 800px) {
					width:calc(100% - 40px);
				}
			}
			.image_article{
				height:220px;
				border-radius: 10px 10px 0 0;
				background-size:cover;
				background-position:center center;
				background-repeat:no-repeat;
			}
			.infos_article{
				padding:30px;
				.tag_article{
					font-size:0.9em;
					color:#A6A6A6;
					margin-top:5px;
				}
				h3{
					margin:auto;
					color:#000;
				}
				.description_article{
					font-size:0.9em;
					color:#818181;
					margin:15px auto 10px;
				}
				.bouton{
					border:2px solid $bleu;
					width:100%;
					background:#FFF;
					color:$bleu;
					padding:6px;
					text-align: center;
					margin-top:20px;
					&:hover{
						color:#FFF;
						background:$bleu;
					}
				}
			}
		}
	}
}

/* ===============================================================================================
GALERIE PHOTO
=================================================================================================*/
.galerie {
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 1000px;
	a {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		height: 200px;
		width: calc(100% / 5 - 14px);
		margin: 7px;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		overflow: hidden;
		div.opacity {
			height: 100%;
			width: 100%;
			transition: 0.2s ease;
			display: block;
			position: absolute;
			background: rgba($bleu, 0.7);
			top: 0;
			left: -100%;
			display:flex;
			img{
				margin:auto;
				width:38px;
				display:block;
			}
		}
		&:hover {
			div.opacity {
				left: 0;
			}
		}
	}
	img {
		height: 55px;
		margin: 40px 20px;
	}
}

/* ===============================================================================================
CONTACT
=================================================================================================*/
#page_contact{
	#header-page{
		height:300px;
		background:$turquoise;
	}
	#contact{
		text-align: center;
		form{
			.input-field.m6:nth-child(3){
				width:100%;
			}
		}
		.bouton{
			margin-top: 25px;
		}
	}
}

#form-contact{
	.card_audit{
		margin: 10px auto;
		form{
			text-align: center;
		}
	}
}
/* ===============================================================================================
FOOTER
=================================================================================================*/
#footer {
	#infos_footer{
		background:$gris;
		padding:40px;
		text-align:center;
		#reseaux_footer{
			display:flex;
			justify-content: center;
			margin-top:20px;
			@media only screen and (max-width: 700px) {
				flex-direction:column;
			}
			>div{
				margin:0 20px;
				width:250px;
				@media only screen and (max-width: 700px) {
					width:100%;
					margin:auto;
				}
				h3{
					margin-top:20px;
				}
				p{
					line-height: 2.4em;
				}
                #container_icones_reseaux{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    a{
                        margin: 0 4px;
                        img{
                            height: 40px;
                            display: block;
                        }   
                    }
                }
			}
		}
	}
	.mentions {
		margin: 0;
		padding: 15px 0px;
		text-align: center;
		font-size: 0.9em;
	}
}

.input-field input:not([type]), .input-field input[type=text], .input-field input[type=password], .input-field input[type=email], .input-field input[type=url], .input-field input[type=time], .input-field input[type=date], .input-field input[type=datetime], .input-field input[type=datetime-local], .input-field input[type=tel], .input-field input[type=number], input[type=search], textarea.materialize-textarea{
	border:none !important;
	background: #F5F5F5!important;
	box-shadow:none !important;
}
textarea.materialize-textarea{
	min-height:180px;
	padding:10px !important;
	margin:auto;
}
.input-field{
	margin:8px auto;
	padding-top:25px !important;
}
.input-field input{
	box-sizing: border-box !important;
	padding:0 10px !important;
}
div.input-field label {
    color: #000000;
    font-weight: 600;
	left: 26px!important;
	&.active{
		color: #000000 !important;
	}
}
.input-field>label:not(.label-icon).active{
	-webkit-transform: translate(-25px, 0px) scale(.85) !important;
    transform: translate(-25px, 0px) scale(.85) !important;
}
.row .col.input-field label{
	-webkit-transform: translate(-15px, 0px) scale(.85) !important;
    transform: translate(-15px, 0px) scale(.85) !important;
}
[type="checkbox"]+span:not(.lever):before, [type="checkbox"]:not(.filled-in)+span:not(.lever):after{
	border-color:$bleu;
}
[type="checkbox"]:checked+span:not(.lever):before{
	border-right: 2px solid $bleu;
	border-bottom: 2px solid $bleu;
}
/* ===============================================================================================
SURCHARGES DE BASE
=================================================================================================*/
.cols_3{
	width:calc(100% / 3 - 50px);
	margin:10px 25px !important;
	@media only screen and (max-width: 1000px) {
		width:calc(100% - 20px);
		margin:10px auto !important;
	}
}
.cols_4{
	width:calc(100% / 4 - 50px);
	margin:10px 25px !important;
	.image_cover{
		height:220px;
	}
	@media only screen and (max-width: 1000px) {
		width:calc(100% - 20px) !important;
		margin:10px !important;
	}
}
@media only screen and (max-width: 1200px) {
	#menu_bouton {
		display: block;
	}
	.text_ck{
		padding:10px;
	}
	#header .menu-item.active>a, #header .menu-item.active>p{
		font-weight:bold;
		color:#FFF !important;
	}
	#header .menu-item ul li>a.active{
		color:#FFF !important;
		padding:10px !important;
	}
	#header {
		top: 0;
		bottom: initial;
		height:75px;
		#logo{
			left:20px;
		}
		.menu {
			height: 100%;
			margin: 0;
			padding: 40px 0;
			height: 100%;
			margin: 0;
			position: fixed;
			transition: all 0.3s ease 0s;
			-webkit-transition: all 0.3s ease 0s;
			-moz-transition: all 0.3s ease 0s;
			-ms-transition: all 0.3s ease 0s;
			-o-transition: all 0.3s ease 0s;
			top: 0;
			z-index: 98;
			overflow-y: auto;
			overflow-x: hidden;
			margin: 0;
			background: $bleu;
			height: 100%;
			opacity: 0;
			visibility: hidden;
			right: -100%;
			width: 100%;
			ul {
				display: flex;
				width: 100%;
				min-width: 200px;
				padding: 0px;
				height: auto;
				align-items: center;
				flex-direction: column;
				border: none;
				li {
					ul {
						display: flex;
						position: static;
						transform: none;
						opacity: 1;
						visibility: visible;
						background: none;
						color: #FFF;
						box-shadow: none;
						margin: auto;
						li {
							margin: 5px auto !important;
							a:before{
								display:none;
							}
						}
					}
					border: none;
					display: block;
					text-align: center;
					vertical-align: middle;
					margin: 10px 0 !important;
					padding: 0;
					&.active{
						>a{
							color:#FFF;
						}
					}
					a {
						margin: auto;
						color: #FFF;
						background:none;
						&:hover{
							color: #FFF;
						}
					}
				}
				li.active {
					a {
						&::before {
							opacity: 1;
							width: 100%;
						}
					}
				}
			}
		}
		.menu-item {
			>a, >p {
				display: inline-block;
				width: auto;
				padding: 0 20px;
				font-size: 1.2em;
				color: #FFF;
				height: auto;
				line-height: 50px;
				margin:auto;
			}
		}
	}
	#header.active {
		.menu {
			opacity: 1;
			visibility: visible;
			display: flex;
			align-items: center;
			right:0;
		}
	}
	.menu-item {
		ul {
			li {
				a {
					padding: 0 0 5px;
				}
				&:hover {
					background: none;
				}
				a.active {
					background: none;
				}
			}
		}
	}
}
@media only screen and (max-width:1200px) {
	.content-slide{
		width:auto;
	}
	#responsive-content.mentionsLegales {
		padding: 2%;
	}
	.menu-item {
		>a {
			&:hover {
				&:after {
					width: 0;
				}
			}
		}
	}
	.menu-item.active {
		>a {
			&:after {
				width: 0;
			}
		}
	}
	#responsive-content {
		.cols {
			border:none !important;
		}
	}
	#breadcrumb {
		>ul {
			padding: 10px 5px;
		}
	}
}
@media only screen and (max-width:1000px) {
	#responsive-content {
		>div.parallax-container {
			height: 200px;
		}
	}
	.contenu_article {
		flex-direction: column;
	}
	.bloc_full_width {
		.cell_50.fond_image {
			min-height: 250px;
		}
	}
}
@media only screen and (max-width:1000px){
	.tableResponsive table, .tableResponsive thead, .tableResponsive tbody, .tableResponsive th, .tableResponsive td, .tableResponsive tr {
		display: block;
	}
	.tableResponsive thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.tableResponsive tr {
		border-top:none;
		display:block;
	}
	.tableResponsive td {
		border: none;
		position: relative;
		padding:10px 5px;
		padding-left: 50%;
		min-height:18px;
		text-align:center;
	}
	.tableResponsive td:before {
		position: absolute;
		top: 50%;
		left: 0;
		transform:translateY(-50%);
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
		text-align: left;
		background: $turquoise;
		height: 100%;
		color: #FFF;
		padding: 10px;
		font-weight: bold;
	}

	/* CONSEILLERS */
	#table_tarifs td:nth-of-type(1):before { content: "Forfait"; }
	#table_tarifs td:nth-of-type(2):before { content: "Tarifs"; }
	#table_tarifs td:nth-of-type(3):before { content: "Durée de validité"; }
	#table_tarifs td:nth-of-type(4):before { content: "Action"; }


}
@media only screen and (max-width:993px) {
	.wrapper {
		.lSAction {
			>a.lSPrev {
				width: 70px;
				height: 70px;
			}
			>a.lSNext {
				width: 70px;
				height: 70px;
			}
		}
	}
}
@media only screen and (max-width:900px) {
	.cols_25 {
		width: 48%;
		margin: auto !important;
		float: none;
		display: inline-block;
		vertical-align: middle;
	}
	.cols_4 {
		width: 48%;
		margin: auto !important;
		float: none;
		display: inline-block;
		vertical-align: top;
	}
}
@media only screen and (max-width:840px) {
	.module_slider{
		height:300px;
		.lslide{
			height:300px;
		}
	}
	#breadcrumb {
		>ul {
			display: block;
		}
	}
}
@media only screen and (max-width:720px) {
	.cols {
		display: block;
		margin: 10px auto;
		width: 100%;
		padding: 0;
	}
	.bloc_image_plus_text {
		.conteneur_image {
			display: block;
			margin: 10px auto;
			width: 100%;
			padding: 0;
		}
	}
	.bloc_image_plus_text_droite {
		.text_ck {
			display: block;
			margin: 10px auto;
			width: 100%;
			padding: 0;
		}
	}
	.cell {
		padding: 0;
	}
	#responsive-content {
		.cols {
			padding: 10px;
			width: 100%;
			display: block;
			margin: 10px 0;
		}
		>div {
			display: block;
		}
	}
	.cols_2 {
		&:last-child {
			padding-left: 0;
		}
	}
}
@media only screen and (max-width:600px) {
	.content-slide {
		width: 100%;
		padding: 20px;
	}
	.galerie {
		a {
			height: 150px;
			width: calc(50% - 25px);
			margin: 10px;
		}
	}
}
@media only screen and (max-width:500px) {
	.module_slider{
		height:200px;
		min-height:0;
		.lslide{
			height:200px;
			min-height:0;
		}
	}
}
@media only screen and (max-width:320px) {
	html {
		font-size: 12px;
	}
	body {
		font-size: 12px;
	}
}